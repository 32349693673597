import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../styles/OwnerDashboard.css';
import { useNavigate } from 'react-router-dom';

const WI_PAY_TAX = 0.035; // WiPay tax rate
const FIXED_USD_FEE = 0.25; // WiPay fixed USD fee
const USD_TO_TTD_RATE = 6.80; // USD to TTD conversion rate

const durationOptionsByStartTime = {
  "11:00 AM": [0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  "11:30 AM": [0.5, 1, 1.5, 2, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5, 11.5, 12.5],
  "12:00 PM": [0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  "12:30 PM": [0.5, 1, 1.5, 2, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5, 11.5],
  "1:00 PM": [0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  "1:30 PM": [0.5, 1, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5, 10.5],
  "2:00 PM": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  "3:00 PM": [1, 2, 3, 4, 5, 6, 7, 8, 9],
  "4:00 PM": [1, 2, 3, 4, 5, 6, 7, 8],
  "5:00 PM": [1, 2, 3, 4, 5, 6, 7],
  "6:00 PM": [1, 2, 3, 4, 5, 6],
  "7:00 PM": [1, 2, 3, 4, 5],
  "8:00 PM": [1, 2, 3, 4],
  "9:00 PM": [1, 2, 3],
  "10:00 PM": [1, 2],
  "11:00 PM": [1] // Only shown on Fridays and Saturdays
};
const AdminDashboard = () => {
  const [reservations, setReservations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookingForm, setBookingForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    duration: 1,
    simulator: 1,
    lesson: 0,
    groupSize: 1,
    price: 0
  });

  const [editMode, setEditMode] = useState(false);
  const [editingBookingId, setEditingBookingId] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [maxDuration, setMaxDuration] = useState(1);
  const [durationOptions, setDurationOptions] = useState([]);
  const [originalBooking, setOriginalBooking] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    fetchReservations();
  }, [selectedDate]);

  useEffect(() => {
    if (editMode && bookingForm.time && bookingForm.duration) {
      setBookingForm((prevForm) => ({
        ...prevForm,
        time: bookingForm.time,
        duration: bookingForm.duration,
      }));
    }
  }, [editMode, bookingForm.time, bookingForm.duration]);

  useEffect(() => {
    if (bookingForm.date) {
      const dayOfWeek = new Date(bookingForm.date).getDay();
      const reservationsForDay = reservations.filter(
        (res) => moment(res.date).format('YYYY-MM-DD') === bookingForm.date
      );
      const availableIntervals = generateHourlyIntervals(dayOfWeek, reservationsForDay, bookingForm.simulator);
      setIntervals(availableIntervals);
  
      if (bookingForm.time) {
        const combinedDateTime = combineDateTime(bookingForm.date, bookingForm.time);
        const closingTime = getClosingTime(combinedDateTime);
        const availableHours = Math.floor((closingTime - combinedDateTime) / (60 * 60 * 1000));
        setMaxDuration(availableHours > 0 ? availableHours : 1);
        const durationOptionsList = generateAvailableDurations(combinedDateTime, availableHours);
        setDurationOptions(durationOptionsList);
      }
    }
  }, [bookingForm.date, bookingForm.time, reservations, bookingForm.simulator]);
  

  useEffect(() => {
    filterAvailableSlotsForDay(moment(selectedDate).format('YYYY-MM-DD'));
  }, [selectedDate, reservations]);

  useEffect(() => {
    if (bookingForm.time) {
      setDurationOptions(durationOptionsByStartTime[bookingForm.time] || []);
    }
  }, [bookingForm.time]);

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setBookingForm((prevDetails) => ({
      ...prevDetails,
      time: selectedTime,
      duration: 1 // Reset duration on time change
    }));

    setDurationOptions(durationOptionsByStartTime[selectedTime] || []);
  };

  const generateDurationOptions = () => {
    return durationOptions.map((duration) => (
      <option key={duration} value={duration}>
        {duration} Hour{duration > 1 ? 's' : ''}
      </option>
    ));
  };
  
  const fetchReservations = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setReservations(data.sort((a, b) => new Date(a.date) - new Date(b.date)));
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  const generateAvailableDurations = (startTime, maxDuration) => {
    const durations = [];
    const startMoment = moment(startTime);

    for (let i = 0.5; i <= maxDuration; i += 0.5) {
      const endMoment = startMoment.clone().add(i, 'hours');
      if (endMoment.hours() < 14 || (endMoment.hours() === 14 && endMoment.minutes() === 0)) {
        durations.push(i);
      } else if (i % 1 === 0) {
        durations.push(i);
      }
    }
    return durations;
  };

  const filterAvailableSlotsForDay = (date) => {
    const dayOfWeek = new Date(date).getDay();
    const intervals = generateHourlyIntervals(dayOfWeek);
    const bookingsForDate = reservations.filter(
      (booking) => moment(booking.date).format('YYYY-MM-DD') === date
    );
    const availableSlots = intervals.map((time) => {
      const sim1Available = isSlotAvailable(time, 1, bookingsForDate);
      const sim2Available = isSlotAvailable(time, 2, bookingsForDate);
      return { time, sim1Available, sim2Available };
    });
    setAvailableTimeSlots(availableSlots);
  };

  const isSlotAvailable = (startTime, simulator, bookingsForDate) => {
    const startMoment = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD h:mm A');
    return !bookingsForDate.some((booking) => {
      const bookingStart = moment(booking.date);
      const bookingEnd = moment(bookingStart).add(booking.duration, 'hours');
      return (
        booking.simulator === simulator &&
        startMoment.isBetween(bookingStart, bookingEnd, null, '[)')
      );
    });
  };
  const handleDelete = async (id) => {
    const token = localStorage.getItem('authToken');
    try {
      await fetch(`${process.env.REACT_APP_NGROK}/api/admin/bookings/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchReservations();
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }
  };
  const initiateWiPayPayment = async (totalAmount, bookingDetails) => {
    const adjustedAmount = (totalAmount - FIXED_USD_FEE * USD_TO_TTD_RATE) / (1 + WI_PAY_TAX);
    const formattedDate = combineDateTime(bookingDetails.date, bookingDetails.time); // Use combined date and time format
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_NGROK}/api/bookings/initiated`, {
        firstName: bookingDetails.firstName,
        lastName: bookingDetails.lastName,
        email: bookingDetails.email,
        phone: bookingDetails.phone,
        date: formattedDate,
        amount: adjustedAmount.toFixed(2),
        duration: bookingDetails.duration,
        simulator: bookingDetails.simulator,
        lesson: bookingDetails.lesson,
        bookingId: bookingDetails.bookingId || null,
        key: '458xf4izac',
        redirect_url: `/api/bookings/confirmation`,
      });
  
      const { payment_url } = response.data;
      window.location.href = payment_url;
      return true;
    } catch (error) {
      console.error('Error initiating payment:', error.response ? error.response.data : error.message);
      return false;
    }
  };
  
  
  const handleCreateBooking = async () => {
    const { firstName, lastName, email, phone, date, time, duration, simulator, lesson, groupSize } = bookingForm;
  
    if (!firstName || !lastName || !email || !phone || !date || !time || !duration || !simulator) {
      alert('Please fill in all required fields.');
      return;
    }
  
    const price = calculatePrice(date, time, duration);
    console.log("Calculated Price:", price);
  
    const bookingDetailsForSubmission = {
      firstName,
      lastName,
      email,
      phone,
      date: combineDateTime(date, time).toISOString(),
      duration,
      simulator,
      lesson,
      groupSize,
      amount: price,
    };
  
    // Initiate the payment process
    await initiateWiPayPayment(price, bookingDetailsForSubmission);
  };
  const handleEdit = (booking) => {
    // Use moment to format the time properly
    const formattedTime = moment(booking.date).format('hh:mm A');

    setEditMode(true);
    setEditingBookingId(booking.id);
    setOriginalBooking({ ...booking });

    setBookingForm({
      firstName: booking.firstName || '',
      lastName: booking.lastName || '',
      email: booking.email || '',
      phone: booking.phone || '',
      date: moment(booking.date).format('YYYY-MM-DD'),
      time: formattedTime,
      duration: booking.duration || 1,
      simulator: booking.simulator || 1,
      lesson: booking.lesson || 0,
      groupSize: booking.size === '1-6' ? 1 : 2,
      price: booking.amount || 0,
    });

    // Set intervals for the selected date and simulator
    const dayOfWeek = new Date(booking.date).getDay();
    const availableIntervals = generateHourlyIntervals(dayOfWeek, reservations, booking.simulator, formattedTime);
    setIntervals(availableIntervals);
  };
  const updateBooking = async (bookingId, bookingDetails) => { 
    try {
      const response = await axios.put(`${process.env.REACT_APP_NGROK}/api/admin/bookings/${bookingId}`, {
        ...bookingDetails,
      });

      if (response.data.success) {
        alert('Booking updated successfully!');
      } else {
        alert('An error occurred while updating the booking.');
      }
    } catch (error) {
      console.error('Error updating booking:', error.response ? error.response.data : error.message);
    }
  };

  const handleUpdateBooking = async () => {
    if (!editingBookingId) {
      console.error('No booking ID available for update');
      return;
    }

    const { firstName, lastName, email, phone, date, time, duration, simulator, lesson, groupSize } = bookingForm;
    const bookingStartTime = combineDateTime(date, time);

    if (!bookingStartTime) {
      alert("Invalid date or time selected.");
      return;
    }

    const formattedDate = bookingStartTime.toISOString();

    // Calculate the new price based on updated duration and time
    const newPrice = calculatePrice(date, time, duration);

    const bookingDetails = {
      firstName,
      lastName,
      email,
      phone,
      date: formattedDate,
      duration,
      simulator,
      lesson,
      groupSize,
      amount: newPrice, 
    };

    try {
      // Calculate price difference for payment
      const priceDifference = newPrice - originalBooking.amount;

      if (priceDifference > 0) {
        const paymentSuccess = await initiateWiPayPayment(priceDifference, bookingDetails);
        if (paymentSuccess) {
          await updateBooking(editingBookingId, bookingDetails);
          alert('Payment completed, booking updated successfully!');
          fetchReservations();
          resetForm();
        } else {
          alert('Payment failed. Booking was not updated.');
        }
      } else {
        await updateBooking(editingBookingId, bookingDetails);
        alert('Booking updated successfully.');
        fetchReservations();
        resetForm();
      }
    } catch (error) {
      console.error('Error updating booking:', error.response ? error.response.data : error.message);
    }
  };
  
  
  const combineDateTime = (date, time) => {
    if (!time || typeof time !== 'string') {
      console.error("Invalid time format:", time); // Debugging log
      return null; // Or handle this case appropriately
    }
  
    const [hourMinute, ampm] = time.split(' ');
    let [hour, minute] = hourMinute.split(':');
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);
  
    if (ampm === 'PM' && hour !== 12) {
      hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
      hour = 0;
    }
  
    // Ensuring a proper Date object is returned
    const dateTimeString = `${date}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;
    const combinedDateTime = new Date(dateTimeString);
  
    if (isNaN(combinedDateTime.getTime())) {
      console.error("Invalid date or time format. Result:", combinedDateTime);
      return null; // Handle invalid date or time case appropriately
    }
  
    return combinedDateTime;
  };
  

  const isOverlap = (date, time, duration, simulator, currentBookingId = null) => {
    const bookingStart = moment(combineDateTime(date, time));
    const bookingEnd = moment(bookingStart).add(duration, 'hours');
  
    return reservations.some((existingBooking) => {
      // Exclude the current booking when checking for overlap
      if (existingBooking.id === currentBookingId || existingBooking.simulator !== simulator) {
        return false;
      }
  
      const existingStart = moment(existingBooking.date);
      const existingEnd = moment(existingStart).add(existingBooking.duration, 'hours');
  
      return bookingStart.isBefore(existingEnd) && bookingEnd.isAfter(existingStart);
    });
  };
  const calculatePrice = (date, time, duration) => {
    const peak = isPeakTime(date, time);
    const cutoffDate = new Date('2024-09-29');
    const isAfterCutoff = new Date(date) > cutoffDate;
  
    const baseRate = isAfterCutoff ? (peak ? 600 : 400) : (peak ? 300 : 200);
    return baseRate * duration;
  };

  const isPeakTime = (date, time) => {
    const bookingDate = new Date(date);
    const dayOfWeek = bookingDate.getDay(); 
    let [timePart, period] = time.split(' ');
    let [hour] = timePart.split(':').map(Number);
  
    if (period === 'PM' && hour !== 12) hour += 12;
    else if (period === 'AM' && hour === 12) hour = 0;
  
    if ((dayOfWeek >= 1 && dayOfWeek <= 3) || dayOfWeek === 6 || (dayOfWeek === 4 && hour >= 11 && hour < 17)) {
      return false; 
    }
    if ((dayOfWeek === 4 && hour >= 17 && hour <= 24) || (dayOfWeek === 5 && hour >= 17 && hour <= 24)) {
      return true;
    }
    return false;
  };

  const resetForm = () => {
    setBookingForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      date: '',
      time: '',
      duration: 1,
      simulator: 1,
      lesson: 0,
      groupSize: 1,
      price: 0,
    });
    setEditMode(false);
    setEditingBookingId(null);
  };

  const getClosingTime = (date) => {
    const closingTime = new Date(date);
    const dayOfWeek = closingTime.getDay();

    if (dayOfWeek === 5 || dayOfWeek === 6) {
      closingTime.setHours(24, 0, 0, 0);
    } else {
      closingTime.setHours(23, 0, 0, 0);
    }

    return closingTime;
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/admin');
  };

  const generateHourlyIntervals = (dayOfWeek, reservationsForDay = [], simulator, currentBookingTime = null) => {
    const intervals = [];
    const startHour = 11; // Opening time at 11 AM
    let endHour;
  
    // Set closing hours based on the day of the week
    if (dayOfWeek === 4 || dayOfWeek === 5) { // Friday and Saturday
      endHour = 24; // Close at midnight
    } else if (dayOfWeek === 0) { // Sunday
      endHour = 23; // Close at 11 PM
    } else {
      endHour = 23; // Close at 11 PM for Monday to Thursday
    }
  
    for (let i = startHour; i < endHour; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const ampm = i < 12 || i === 24 ? 'AM' : 'PM';
      const formattedHour = hour < 10 ? `0${hour}` : hour;
      const fullHourTime = `${formattedHour}:00 ${ampm}`;
      const halfHourTime = `${formattedHour}:30 ${ampm}`;
  
      // Include half-hour intervals before 2 PM
      if (i < 14) {
        intervals.push(fullHourTime);
        intervals.push(halfHourTime);
      } else {
        intervals.push(fullHourTime);
      }
  
      // Always include the current booking time in the list, even if booked
      if (currentBookingTime && intervals.includes(currentBookingTime) === false) {
        intervals.push(currentBookingTime);
      }
    }
  
    return intervals;
  };
  

  return (
    <div className="owner-dashboard admin-dashboard-container">
      <div className="left-section">
        <h1>Admin Dashboard</h1>
        <div className="calendar-container">
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            tileContent={({ date }) => {
              const dateString = moment(date).format('YYYY-MM-DD');
              const bookingsForDay = reservations.filter(
                (res) => moment(res.date).format('YYYY-MM-DD') === dateString
              );
              return bookingsForDay.length ? <div>{bookingsForDay.length} booking(s)</div> : null;
            }}
          />
        </div>
        <h3>Bookings for {moment(selectedDate).format('dddd MMM Do YYYY')}</h3>
        <div className="bookings-container">
          <div className="sim-column">
            <div className="simulator-section">
              <h4>Sim Zone</h4>
              {availableTimeSlots.map((slot, index) => (
                <div key={index} className={`time-slot ${slot.sim1Available ? 'available' : 'unavailable'}`}>
                  {slot.sim1Available ? (
                    <p>{slot.time} - Available</p>
                  ) : (
                    reservations
                    .filter((res) => 
                      moment(res.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') && 
                      res.simulator === 1 && 
                      moment(res.date).format('hh:mm A') === slot.time
                    )
                    .map((res) => (
                      <div className="reservation-card" key={res.id}>
                        <h4>
                          {res.firstName} {res.lastName}
                        </h4>
                        <p>Email: {res.email}</p>
                        <p>Phone: {res.phone}</p>
                        <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                        <p>Duration: {res.duration} hour(s)</p>
                        <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                        <p>Group Size: {res.size ? '1-6' : '6+'}</p>
                        <p>Status: {res.paid === false ? 'Unpaid' : 'Paid'}</p>
                        <div className="reservation-buttons">
                          
                          <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ))}
            </div>
            <div className="simulator-section">
              <h4>Golf Den</h4>
              {availableTimeSlots.map((slot, index) => (
                <div key={index} className={`time-slot ${slot.sim2Available ? 'available' : 'unavailable'}`}>
                  {slot.sim2Available ? (
                    <p>{slot.time} - Available</p>
                  ) : (
                    reservations
                    .filter(
                      (res) =>
                        moment(res.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') &&
                        res.simulator === 2 &&
                        moment(res.date).format('hh:mm A') === slot.time
                    )
                    .map((res) => (
                      <div className="reservation-card" key={res.id}>
                        <h4>
                          {res.firstName} {res.lastName}
                        </h4>
                        <p>Email: {res.email}</p>
                        <p>Phone: {res.phone}</p>
                        <p>Time: {moment(res.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                        <p>Duration: {res.duration} hour(s)</p>
                        <p>Lesson: {res.lesson === 0 ? 'None' : res.lesson === 1 ? 'Kids Lesson' : 'Adult Lesson'}</p>
                        <p>Group Size: {res.size ? '1-6' : '6+'}</p>
                        <p>Status: {res.paid === false ? 'Unpaid' : 'Paid'}</p>
                        <div className="reservation-buttons">
                          
                          <button className="delete-btn" onClick={() => handleDelete(res.id)}>Delete</button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="right-section">
        <h3>{editMode ? 'Edit Booking' : 'Create Booking'}</h3>
        <div className="form-container">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={bookingForm.firstName}
            onChange={(e) => setBookingForm({ ...bookingForm, firstName: e.target.value })}
            className="custom-input"
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={bookingForm.lastName}
            onChange={(e) => setBookingForm({ ...bookingForm, lastName: e.target.value })}
            className="custom-input"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={bookingForm.email}
            onChange={(e) => setBookingForm({ ...bookingForm, email: e.target.value })}
            className="custom-input"
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={bookingForm.phone}
            onChange={(e) => setBookingForm({ ...bookingForm, phone: e.target.value })}
            className="custom-input"
          />
          <input
            type="date"
            name="date"
            value={bookingForm.date}
            onChange={(e) => setBookingForm({ ...bookingForm, date: e.target.value })}
            className="custom-datepicker"
          />
          <select
            name="time"
            value={bookingForm.time || ''}  // Explicitly set to empty string if null/undefined
            onChange={handleTimeChange}
          >
            <option value="">Select Time</option>
            {Object.keys(durationOptionsByStartTime).map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
  
          <select
            name="duration"
            value={bookingForm.duration}
            onChange={(e) => setBookingForm({ ...bookingForm, duration: parseFloat(e.target.value) })}
          >
            <option value="">Select Duration</option>
            {generateDurationOptions()}
          </select>
          <select
            name="simulator"
            value={bookingForm.simulator}
            onChange={(e) => setBookingForm({ ...bookingForm, simulator: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={1}>Sim Zone</option>
            <option value={2}>Golf Den</option>
          </select>
          <select
            name="groupSize"
            value={bookingForm.groupSize}
            onChange={(e) => setBookingForm({ ...bookingForm, groupSize: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={1}>1-6</option>
            <option value={2}>6+</option>
          </select>
          <select
            name="lesson"
            value={bookingForm.lesson}
            onChange={(e) => setBookingForm({ ...bookingForm, lesson: parseInt(e.target.value) })}
            className="custom-input"
          >
            <option value={0}>None</option>
            <option value={1}>Kids Lesson</option>
            <option value={2}>Adult Lesson</option>
          </select>
          <p>Price: ${bookingForm.price !== undefined ? bookingForm.price.toFixed(2) : '0.00'}</p>

          {/* Display calculated price */}
          <button className="create-btn" onClick={editMode ? handleUpdateBooking : handleCreateBooking}>
            {editMode ? 'Update Booking' : 'Create Booking'}
          </button>
          {editMode && (
            <button className="cancel-btn" onClick={resetForm}>Cancel Edit</button>
          )}
        </div>
        <button className="logout-btn" onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default AdminDashboard;
